
/* 
 * jQuery functions
 *
 * Open or close descriptive text under individual projects in one row. 
 * Note that we initially close the descriptions in common.scss
 *
 * Note: Foundation incorporates this into app.js
 */

function toggleProjectDescriptions ( c, t ) {

    var ariaHidden ="false";

    $( c ).toggleClass( 'plus-minus-open' );

        // change the text on the toggling element

        if ( $( c ).hasClass( 'plus-minus-open' ) ) {

            ariaHidden = "false";

            $( t ).html( '- close' );

            $( t ).attr( 'aria-expanded', "true" );

        } else {

            ariaHidden = "true";

            $( t ).html( '+ open description' );

            $( t ).attr( 'aria-expanded', "false" );

        }

        // open or close extra elements

        var cl = c.getElementsByClassName( 'projects-grid-description-short' );

        for( var i = 0, len = cl.length; i < len; i++ ) {

            // jQuery animation

            $( cl[i] ).attr( 'aria-hidden', ariaHidden );

            $( cl[i] ).slideToggle('1000', function () { 

            //animation complete

        } );

    }

}

// Toggle on click on the '+' or '-' in the project grid

$('.plus-minus').click( function ( e ) {

    console.log('clicked plus-minus')

    e.preventDefault();

    // toggle plus-minus

    var c = this.parentNode;

    toggleProjectDescriptions( c, e.target );

} );

// Keyboard support for toggle

$('.plus-minus').keydown( function ( e ) {

    var key = (event.keyCode ? event.keyCode : event.which); 

    switch( key ) {

        case 13:  /* key enter */
        case 32:  /* key space */

        var c= this.parentNode;

        toggleProjectDescriptions( c, e.target );
    }

} );


